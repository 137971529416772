@use "../../scss/abstracts/" as *;

.main-auth {
  .form-control.form-secondary {
    color: $black;
    background: lighten($secondaryColor, 35) !important;
    box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
    border-color: $secondaryColor;
  }
  h1 {
    color: $black;
    font-size: 4.68rem;
    font-family: $tt-demibold;
    @media (max-width: 1199px) {
      font-size: 3.5rem;
    }
    @media (max-width: 991px) {
      font-size: 2.5rem;
    }
    @media (max-width: 576px) {
      font-size: 2rem;
    }
  }
}
.container-auth {
  position: relative;
  padding: 0 15px;
}
.row-auth {
  min-height: 100vh;
}
.leftLogin {
  background-color: $white;
  position: relative;
  box-shadow: 80px 10px 80px lighten($secondaryColor, 15);
  @media (max-width: 991px) {
    display: none;
  }
  .innerLeftLogin {
    padding: 15px 0;
  }
  .img-login {
    position: absolute;
    bottom: 25px;
    left: 45px;
    right: 45px;
    padding: 50px 50px 0;
    background: url(../../assets/images/bg-auth-digi.svg) no-repeat;
    background-size: 100%;
    img {
      width: 100%;
      height: auto;
    }
    @media (max-width: 767px) {
      position: static;
    }
  }
}
.righttLogin {
  background: url(../../assets/images/bg-digi.png) no-repeat center;
  background-size: cover;
  position: relative;
  .innerrightLogin {
    padding-top: 50px;
    @media (max-width: 767px) {
      padding-top: 20px;
    }
  }
  .tabs-component {
    .tabpanel {
      background-color: transparent;
    }
    .tablist {
      background: transparent;
      box-shadow: none;
      position: absolute;
      top: 150px;
      left: -265px;
      padding: 0;
      @media (max-width: 991px) {
        display: none;
      }
      .btn {
        position: relative;
        min-height: 100px;
        min-width: 265px;
        font-size: 1.5rem;
        padding: 0.5rem 0.5rem 0.5rem 2.5rem;
        color: $black;
        font-family: $tt-demibold;
        text-align: left;

        span {
          position: relative;
          z-index: 1;
          font-size: 1.5rem;
          font-family: $tt-demibold;
        }
        &.active {
          background-color: transparent;
          box-shadow: none;

          &::before {
            content: "";
            position: absolute;
            top: 43%;
            height: 185px;
            width: 265px;
            right: 1px;
            background: url(../../assets/images/tabs-bg-digi.svg) no-repeat center;
            background-size: 100%;
            transform: translateY(-50%);
            z-index: 0;
          }
        }
      }
    }
  }
}
