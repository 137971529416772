@use "../../scss/abstracts/"as *;

.single-sync-page {
    h3 {
        color: $primaryColor;
        border-color: $primaryColor;
        border-bottom: 2px dashed #b0b0b0;
        font-size: 27px;
        padding-bottom: 12px;
        margin-bottom: 30px;
        width: 70%;
        font-family: $tt-demibold;
    }

    p {
        line-height: 35px;
        font-size: 25px;
        font-family: $tt-demibold;
        margin: 0;
        color: $primaryColor;
    }
}