@import "./fonts";

$bodyBg: #f8f8f8;
$mainBg: #fff;
$box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
$transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);

// $primaryColor: #056e9c;
$primaryColor: #202c46;
// $secondaryColor: #202c46;
$secondaryColor: #fd7e14;
$terColor: #feb254;

$txtColor: #4e4d4f;
$colorLink: #b0b0b0;
$purple: #603dd9;
$orange: #feb254;
$white: #fff;
$black: #000;
$green: #43b59c;
$red: #fe0000;
$yellow: #fac754;
$green-light: #51b6ab;
$default: rgba(176, 176, 176, 0.6);

$fontFamily: "Roboto", sans-serif;

$tt-italic: "tt_commonsblack_italic";
$tt-demibold: "tt_commonsdemibold";
$tt-medium: "tt_commonsmedium";
$tt-light: "tt_commonslight";
$tt-regular: "tt_commonsregular";
$tt-thin: "tt_commonsthin";
$tt-extrabold: "tt_commonsextrabold";
$tt-bold: "tt_commonsbold";

$fontSizeNormal: 1rem;
$fontSizeMedium: 1.25rem;
$fontSizeLarge: 1.5625rem;

$sideBarWidth: 320px;
$sideBarLogoHeight: 171px;
$spacing: 30px;
$mobileSpacing: 24px;
$borderRadius: 30px;

$mobile-width: 600px;
$tablet-width: 1366px;
